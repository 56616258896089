@use '@angular/material' as mat;
@import 'theme';


@include mat.all-component-themes($rip-theme);

.ripeurs-operator-app-theme-lyon {
	@include mat.all-component-themes($rip-lyon-theme);
}

.ripeurs-operator-app-theme-marseille {
	@include mat.all-component-themes($rip-marseille-theme);
}

html, body {
	height: 100%;
	overscroll-behavior: contain;
}

body {
	margin: 0;
	font-family: 'Roboto', sans-serif;
	ripeurs-operator-app-root * {
		letter-spacing: 0;
	}
}

.cdk-overlay-container {
	max-width: 100vw !important;
	max-height: 100vh !important;

	.mat-mdc-autocomplete-panel .rip-highlight,
	.mat-autocomplete-panel .rip-highlight {
		text-decoration: underline;
		color: mat.get-color-from-palette($rip-accent, default);
	}

	div.cdk-overlay-pane {
		max-width: min(700px, 95vw) !important;
		max-height: 95vh !important;
		margin: 0 auto !important;
	}
}

@media (min-width: 600px) {
	ripeurs-operator-app-root ng-component {
		max-width: 600px;
		padding: 0 calc(50% - 300px);
		overflow: auto !important;
		width: 100%;
	}
}

.mat-mdc-progress-spinner {
	& > svg {
		width: inherit !important;
		height: inherit !important;
	}

	&.rip-loader-24 {
		width: 24px !important;
		height: 24px !important;
	}

	&.rip-loader-32 {
		width: 32px !important;
		height: 32px !important;
	}

	&.rip-loader-48 {
		width: 48px !important;
		height: 48px !important;
	}

	&.rip-loader-64 {
		width: 64px !important;
		height: 64px !important;
	}

	&.rip-loader-96 {
		width: 96px !important;
		height: 96px !important;
	}
}

button.rip-button-loader {
	overflow: hidden;

	.mat-mdc-progress-bar {
		position: absolute;
		top: 26px;
		left: 50%;
		width: calc(100vw);
		transform: translateX(-50%);
	}

	&[disabled] {
		.mat-mdc-progress-bar {
			.mat-mdc-progress-bar-background {
				fill: rgba(0, 0, 0, 0.0);
			}

			.mat-mdc-progress-bar-buffer {
				background-color: rgba(0, 0, 0, 0.0);
			}

			.mat-mdc-progress-bar-fill::after {
				background-color: rgba(0, 0, 0, 0.26);
			}
		}
	}
}

.rip-margin-auto {
	margin-left: auto;
	margin-right: auto;
}

.rip-margin-top-8 {
	margin-top: 8px !important;
}

.rip-margin-top-12 {
	margin-top: 12px !important;
}

.rip-margin-top-16 {
	margin-top: 16px !important;
}

.rip-margin-top-24 {
	margin-top: 24px !important;
}

.rip-margin-top-32 {
	margin-top: 32px !important;
}

.rip-margin-right-8 {
	margin-right: 8px !important;
}

.rip-margin-right-12 {
	margin-right: 12px !important;
}

.rip-margin-right-16 {
	margin-right: 16px !important;
}

.rip-margin-right-24 {
	margin-right: 24px !important;
}

.rip-margin-right-32 {
	margin-right: 32px !important;
}

.rip-margin-bottom-8 {
	margin-bottom: 8px !important;
}

.rip-margin-bottom-12 {
	margin-bottom: 12px !important;
}

.rip-margin-bottom-16 {
	margin-bottom: 16px !important;
}

.rip-margin-bottom-24 {
	margin-bottom: 24px !important;
}

.rip-margin-bottom-32 {
	margin-bottom: 32px !important;
}

.rip-margin-left-8 {
	margin-left: 8px !important;
}

.rip-margin-left-12 {
	margin-left: 12px !important;
}

.rip-margin-left-16 {
	margin-left: 16px !important;
}

.rip-margin-left-24 {
	margin-left: 24px !important;
}

.rip-margin-left-32 {
	margin-left: 32px !important;
}

.rip-text-center {
	text-align: center;
}

.rip-text-italic {
	font-style: italic;
}

.mat-mdc-outlined-button > .mat-icon {
	margin: 0 !important;
}
