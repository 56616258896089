@use '@angular/material' as mat;

@include mat.all-component-typographies();
@include mat.core();

:root {
	--primary-color-100: #ffd8b8;
	--primary-color-500: #ff7737;
	--primary-color-700: #ff6a0c;
	--contrast-color-100: #000000;
	--contrast-color-500: #ffffff;
	--contrast-color-700: #ffffff;
}

.ripeurs-operator-app-theme-default {
	--primary-color-100: #ffd8b8;
	--primary-color-500: #ff7737;
	--primary-color-700: #ff6a0c;
	--contrast-color-100: #000000;
	--contrast-color-500: #ffffff;
	--contrast-color-700: #ffffff;
}
.ripeurs-operator-app-theme-lyon {
	--primary-color-100: #9b1b30;
	--primary-color-500: #77132c;
	--primary-color-700: #5a0f22;
	--contrast-color-100: #ffffff;
	--contrast-color-500: #ffffff;
	--contrast-color-700: #ffffff;
}
.ripeurs-operator-app-theme-marseille {
	--primary-color-100: #84c9ff;
	--primary-color-500: #2FAEE0;
	--primary-color-700: #0073b9;
	--contrast-color-100: #000000;
	--contrast-color-500: #ffffff;
	--contrast-color-700: #ffffff;
}

$rip-theme-primary: mat.define-palette((
	100: var(--primary-color-100),
	500: var(--primary-color-500),
	700: var(--primary-color-700),
	contrast: (
		100: var(--contrast-color-100),
		500: var(--contrast-color-500),
		700: var(--contrast-color-700),
	),
));
$rip-theme-accent: mat.define-palette(mat.$blue-palette);
$rip-theme-warn: mat.define-palette(mat.$red-palette);
$rip-theme: mat.define-light-theme((
	color: (
		primary: $rip-theme-primary,
		accent: $rip-theme-accent,
		warn: $rip-theme-warn,
	),
));
$rip-primary: map-get($rip-theme, primary);
$rip-accent: map-get($rip-theme, accent);
$rip-warn: map-get($rip-theme, warn);
$rip-foreground: map-get($rip-theme, foreground);
$rip-background: map-get($rip-theme, background);
$rip-normal-level: mat.define-typography-level(
	$font-family: Roboto,
	$font-weight: 400,
	$font-size: 1rem,
	$line-height: 1,
	$letter-spacing: normal,
);

$rip-lyon-theme-primary: mat.define-palette((
	100: var(--primary-color-100),
	500: var(--primary-color-500),
	700: var(--primary-color-700),
	contrast: (
		100: var(--contrast-color-100),
		500: var(--contrast-color-500),
		700: var(--contrast-color-700),
	),
));
$rip-lyon-theme-accent: mat.define-palette(mat.$blue-palette);
$rip-lyon-theme-warn: mat.define-palette(mat.$red-palette);
$rip-lyon-theme: mat.define-light-theme((
	color: (
		primary: $rip-lyon-theme-primary,
		accent: $rip-lyon-theme-accent,
		warn: $rip-lyon-theme-warn,
	),
));

$rip-marseille-theme-primary: mat.define-palette((
	100: var(--primary-color-100),
	500: var(--primary-color-500),
	700: var(--primary-color-700),
	contrast: (
		100: var(--contrast-color-100),
		500: var(--contrast-color-500),
		700: var(--contrast-color-700),
	),
));
$rip-marseille-theme-accent: mat.define-palette(mat.$blue-palette);
$rip-marseille-theme-warn: mat.define-palette(mat.$red-palette);
$rip-marseille-theme: mat.define-light-theme((
	color: (
		primary: $rip-marseille-theme-primary,
		accent: $rip-marseille-theme-accent,
		warn: $rip-marseille-theme-warn,
	),
));

/*
$rip-primary:
$rip-accent:
$rip-warn:
	default
	lighter
	darker

$rip-foreground:
	base
	divider
	dividers
	disabled
	disabled-button
	disabled-text
	hint-text
	secondary-text
	icon
	icons
	text
	slider-off
	slider-off-active

$rip-background:
	status-bar
	app-bar
	background
	hover
	card
	dialog
	disabled-button
	raised-button
	focused-button
	selected-button
	selected-disabled-button
	disabled-button-toggle
*/
